.quantity-to-troli {
    margin-top: 30px;
}
.eq-section {
    flex: 1; /* Shrink and grow according to available height */
    flex-basis: 1;
}
.eq-item {
    /* flex: 1 1 auto; */
    display: flex;
}
.input-width {
    margin: 0 !important;
    max-width: 80px !important;
}
.field-no-margin {
    margin: 0 !important;
}
.bottom {
    order: 0;
    flex: 0 1 auto;
    align-self: flex-end;
}
.middle-center {
  flex: 0 1 auto;
  align-self: center;
}
.left-center {
    flex: 0 1 auto;
    margin-right: 8px;
    /* margin: auto; */
    align-self: center;
}
.right-center {
    order: 1;
    margin-left: 8px;
    flex: 0 1 auto;
    /* margin: auto; */
    align-self: center;
}

/* 
.grid-items {
    display: flex;
    align-items: center;
    justify-content: center;
} */

/*BUTTON*/
.ui.button.button-troli {
    background-color: #e25757;
    color: white;
}
.ui.button.button-troli:hover {
    background-color:#d45050;
    color: white;
}