h3.heading-kategori {
    font-family: 'Montserrat', sans-serif;
}

a.sub_kategori {
    color: #3f3f3f;
}

a.sub_kategori:hover {
    color: #e25757
}

.sub_menu {
    font-family: 'Roboto', sans-serif !important;
    font-size: 0.9em !important;
}

/*detail*/
.result_keterangan {
    margin-bottom: 10px;
}