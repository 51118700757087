.btn-membership-register {
  border-radius: 10px;
  color: white;
  height: 50px;
  font-size: 14px;
  margin: 30px 0px 0px 0px;
  border: none;
  width: 200px;
  background-color: red;
}
.btn-membership-register:hover {
  cursor: pointer;
}
.secmem-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60vh;
}

.secmem-container > .left-element {
  flex: 60%;
  height: auto;
  display: flex;
  align-items: center;
}

.secmem-container > .left-element > .lead-card {
  border: 2px antiquewhite solid;
  background-color: antiquewhite;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 40px 40px 40px 40px;
  border-radius: 40px;
  height: auto;

  width: 100%;
}

.secmem-container > .right-element {
  display: flex;
  justify-content: flex-end;
  flex:40%
}

.secmem-container > .right-element > .img {
  background: url('../../../public/assets/img/secmembership_bag.png') no-repeat;
  background-size: 500px 500px;
  align-self: center;
  height: 500px;
  width: 500px;
  position: relative;
}