h3.heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.alamat_utilities_button {
    margin-top: 1em;
}
h4.heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
}

div.pool-pengiriman-instant {
    margin-top: 1em;
}
div.pool-pengiriman-reguler {
    margin-top: 1em;
}
.pool-pengiriman-reguler .day {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

.catatan-tambahan {
    margin-top: 1em;
}
.detail-pembayaran {
    margin-top: 2em;
}

.alamat-pengiriman-section {
    flex: 1;
    flex-basis: 1;
}
.alamat-pengiriman-item {
    display: flex;
}
.middle-center {
    order: 0;
    flex: 0 1 auto;
    align-self: center;
}
