.box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.eq-card.ui.card {
    flex: 1; /* Shrink and grow according to available height */
    flex-basis: 0;
}

.img-parent {
    display: -ms-flex; display: -webkit-flex; display: flex;
}
img.eq-image {
    flex: 1 1 auto;
    height: 100%;
    border-radius: 20% !important;
    border-collapse: separate; 
}

.featured-kat-text {
    background-color: #f2f2f2;
    color: #2b2b2b;
    z-index: 100;
    position: absolute;
    padding: 8px;
    left: 0;
    bottom: 0;
    font-weight: bold;
}

.featured-kat-semua-kategori {
    margin: auto;
    color: #2b2b2b;
    font-weight: 600;
}