:root {
--gutter: 20px;
}

.nata-hs-container {
padding: 0;
display: grid;
grid-gap: var(--gutter);
grid-template-columns: var(--gutter) 1fr var(--gutter);
align-content: start;
}

.nata-hs-container > * {
grid-column: 2 / -2;
}

.nata-hs-container > .full {
grid-column: 1 / -1;
}

.hs {
    display: grid;
  grid-gap: calc(var(--gutter) / 2);
  /* grid-template-columns: 10px repeat(var(--total), calc(100% - var(--gutter) * 2)) 10px; */
  grid-template-columns: 0 repeat(var(--total), calc(100% - var(--gutter) * 2));
  grid-template-rows: minmax(150px, 1fr);
  
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(.75 * var(--gutter));
  margin-bottom: calc(-.25 * var(--gutter));
}
.hs::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.hs:before,
.hs:after {
  content: '';
}

/*demo*/
ul.nata-hs-ul {
    list-style: none;
    padding: 0;
  }

.nata-hs-container {
    width: 100%;
    height: auto;
    overflow-y: hidden;
}
.hs > li,
.nata-item {
    /* scroll-snap-align: start; */
  scroll-snap-align: center;
  padding: 0;

  /* padding: calc(var(--gutter) / 2 * 1.5); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
}