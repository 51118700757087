.search_pagination {
    margin-top: 20px;
}
.search-input {
    color:#2b2b2b !important;
}

.search-panel {
  position: relative;
}

.search-holder {
  position: relative;
  width: 100%;
  margin: 8px;
}
.search-holder-utilities-buttons {
  position: relative;
}
.search-suggest-panel {
  margin-top: 54px;
  z-index: 10;
  width: 100%;
  height: 300px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.search-suggest-panel .suggestion-items {
  color: #2b2b2b;
  margin-top: 5px;
  margin-bottom: 5px;
}
/* .search-holder .dropdown {
  position: absolute;
    border: 1px solid red;
    display: none;
    z-index: 500;
} */
.ui.fixed.menu.search-menu {
  height: 54px;

}

.search-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555454 !important;
    opacity: 1 !important; /* Firefox */
  }
  
.search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #555454 !important;
  }
  
.search-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #555454 !important;
  }