.email_warning {
    color: red;
    font-size: 0.9em;
}

.ponsel_warning {
    color: red;
    font-size: 0.9em;
}

.email_valid {
    color: green;
    font-size: 0.9em;
}

.ponsel_valid {
    color: green;
    font-size: 0.9em;
}