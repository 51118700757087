.featured-asset {
    width: 180px;
    height: auto;
}
h3.heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
p.story {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
}