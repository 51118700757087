h3.heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.card.border {
    background:white;
    border-radius: 12px !important;
    margin-right: 12px;
}
.card.shadow-item {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0,0.1);
    
}

.dimmed.dimmable > .ui.modals.dimmer.visible {
    display: flex !important;
  }

/*BARANG*/
.product_p_nama_barang_text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.02em;
}
.product_p_harga_barang_text {
    font-family: 'Open Sans', sans-serif;
    color: #e25757;
    font-size: 1.05em;
    font-weight: bold;
}
.product_p_diskon_condition_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #bc1c1c;
    font-size: 0.9em;
}
.product_p_dsc_harga_barang_normal_text {
    font-family: 'Open Sans', sans-serif;
    color: #3f3f3f;
    font-size: 0.9em;
    font-weight: bold;
}
.product_p_dsc_harga_barang_after_diskon_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #e25757;
    font-size: 1.05em;
    font-weight: bold;
}
.product_item {
    cursor: pointer;
}

.ringkasan_subtotal_text {
    font-family: 'Open Sans', sans-serif;
    color: #3f3f3f;
    font-size: 1.05em;
    font-weight: bold;
}
.ringkasan_diskon_text {
    font-family: 'Open Sans', sans-serif;
    color: #e25757;
    font-size: 1.05em;
    font-weight: bold;
}
.ringkasan_total_final_text {
    font-family: 'Open Sans', sans-serif;
    color: #e25757;
    font-size: 1.05em;
    font-weight: bold;
}
.ringkasan_total_final_grid {
    margin-top: 30px;
}
.ui.button.ringkasan_checkout_button {
    background-color: #e25757;
    color: white;
}
.ui.button.ringkasan_checkout_button:hover {
    background-color:#d45050;
    color: white;
}