@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600|Open+Sans:600|Roboto&display=swap');
body {
    font-family: 'Roboto', sans-serif;
}
.main.container {
margin-top: 7em;
}
.wireframe {
margin-top: 2em;
}

a.flexing.item {
    display: flex;
}
.footer-text-link {
    flex: 1;
    flex-basis: 0;
}
h4.footer-heading {
    font-family: 'Montserrat', sans-serif !important;
}
.playstore-img-footer {
    width: 120px;
    height: auto;
}
.seo-home {
    font-size: 0.85em;
}
.logo-footer {
    width: 120px;
    height: auto;
}